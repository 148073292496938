/**
 * String list of pages which should be shown on footer
 */

import { environment } from '@fundo/environments/environment';

export const TERM_PAGES = {
  esign: `${environment.main_page}/e-sign/`,
  messagingTerms: `${environment.main_page}/messaging-terms/`,
  privacyPolicy: `${environment.main_page}/privacy-policy/`,
  termsOfService: `${environment.main_page}/terms-of-service/`,
};

// Terms: These pages are arranged in rendering order
export const termPages = [
  // {
  //   id: 'non-eligible-banks',
  //   link: `${}`,
  //   showNavigation: true,
  //   linkOnFooter: false,
  //   showHomeButton: false,
  // },
  {
    id: 'e-sign',
    link: TERM_PAGES.esign,
  },
  {
    id: 'messaging-terms',
    link: TERM_PAGES.messagingTerms,
  },
  {
    id: 'privacy-policy',
    link: TERM_PAGES.privacyPolicy,
  },
  {
    id: 'terms-of-service',
    link: TERM_PAGES.termsOfService,
  },
];
