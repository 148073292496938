export enum SeoLandingPage {
  MerchantCashAdvance = 'merchant-cash-advance',
  Uber = 'uber',
  Lyft = 'lyft',
  GigWorker = 'gig-worker',
  Doordash = 'doordash',
  SelfEmployed = 'self-employed',
  Freelance = 'freelance',
  Business = 'business-loans',
  SmallBusiness = 'small-business-loans',
  Funding = 'funding',
}
